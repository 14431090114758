// variables and overrides
@import './variables.scss';
// bootstrap defaults
@import 'node_modules/bootstrap/scss/bootstrap.scss';
// partials and components
@import './window.scss';

@mixin svg-icon() {
  content: '';
  background-repeat: no-repeat;
  background-position: center;
}
